// Publisher Article
.regular-publisher-article {
  h1 {
    margin-bottom: $spacer-lg;
  }
}

// Heimat Article
.regular-heimat-article {

  .sve-article-catchline {
    color: $heimat;
  }

  //hr {
  //  background-color: c(neutral-70);
  //  margin: 1.5rem 0 1.75rem;
  //}
}

// Advertorial Articles
.regular-advertorial-article,
.regular-publisher-article {
  background-color: $advertorial-background;

  .fp-header,
  .sve-main {
    background-color: $advertorial-background;
  }

  .fp-section .fp-section-header h2,
  .fp-header__fixed .fp-header_container {
    background-color: $advertorial-background;
  }

  .sve-article-catchline {
    color: $advertorial;
  }

  .sve-popular-articles {
    //max-width: 100%;
    //width: 100%;
    //padding: 0;
    //max-width: initial;
    //padding: $spacer-lg $spacer-base;
    //margin-left: calc(-50vw + (100vw - 200px) / 2);
    //margin-right: calc(-50vw + (100vw - 200px) / 2);
    //width: calc(100% + 200px);

    @include media-breakpoint-down(lg) {
      //margin-left: calc(-50vw + (100vw - 32px) / 2);
      //margin-right: calc(-50vw + (100vw - 32px) / 2);
      //width: calc(100% + 32px);
    }

    .sve-popular-list_header {
      background-color: c(neutral-100);
    }
  }
}

// All articles
.regular-advertorial-article,
.regular-publisher-article,
.regular-heimat-article {
  .fp-header__fixed {
    padding-top: 40px;
  }

  .fp-header__static-links ul a.selected {
    background-color: c(neutral-100);
  }
}

.regular-advertorial-article,
.regular-publisher-article,
.regular-heimat-article,
.regular {
  .fp-header__fixed {
    padding-top: 40px;

    @include media-breakpoint-down(lg) {
      padding-top: 50px;
    }
  }
}
