.fp-teaser {
  a:hover {
    text-decoration: none;
  }
}

.fp-teaser_content {
  padding: 1.5rem;
}

.fp-article-group-small {
  max-width: 1030px;
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  .fp-teaser_title {
    font-size: $text-xl;
  }

  .fp-teaser_content {
    padding: 0.5rem 0 1rem;
  }

  .fp-article-group-small {
    //margin: 0 1rem;
  }

  .fp-article-main.fp-section {
    padding: 0 0 $spacer-base;
  }
}

@media screen and (min-width: calc(#{$container-width} + 16px)) {
  .fp-section {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 60px;
    padding-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }

  .fp-section-no-bottom {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
  }
}

.fp-article-info-image {
  margin-left: calc(-100vw / 2 + (100vw - 368px) / 2);
  margin-right: calc(-100vw / 2 + (100vw - 368px) / 2);
  width: calc(100% + 368px);
  font-size: 0;

  @media (max-width: 992px) {
    margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
    margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
    width: calc(100% + 32px);
  }

  img {
    width: 100%;
  }

  figcaption {
    text-align: center;
    font-size: 0.875rem;
    margin-top: 12px;
    line-height: 1.4;

    span {
      color: c(neutral-60);
    }
  }
}

.sve-section {
  &_title {
    display: flex;
    border-top: 1px solid $on-background;
    padding: $spacer-base 0;
    font-size: 36px;
    line-height: 1;
    margin-bottom: $spacer-xs;

    @include media-breakpoint-down(md) {
      font-size: 22px;
    }

    > a {
      @include arrow-right-styles($black-arrow);
      color: c(neutral-10);
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;
    border-top: 1px solid c(neutral-90);
    border-bottom: 1px solid c(neutral-90);
  }
}

.sve-select-heimat {
  margin-bottom: 60px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
  }

  .sve-section {
    &_title {
      color: $heimat;
      border-top: 1px solid $heimat;

      &::after {
        content: none;
      }
    }

    &_title > a {
      @include arrow-right-styles($heimat-arrow);
      color: $heimat;
    }
  }
}

.sve-select-region {
  margin-bottom: 60px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
  }

  .sve-section {
    &_title {
      @include arrow-right-styles($primary-color-arrow);
      color: $primary-color;
      border-top: 1px solid $primary-color;
    }
  }
}

.editor-teaser-small_img {
  height: 140px;
}
